import { useAuthentication } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';

import { SingleFormPage } from '../components';

const PAGE_TITLE = 'Page not found';

const Error404Page = () => {
  const { isAuthenticated } = useAuthentication();

  return (
    <SingleFormPage meta={{ seoDescription: 'page not found', seoTitle: '404' }} title="Login">
      <h1 className="color-primary h5 mb-2 text-uppercase">404</h1>
      <h2 className="color-black mb-6 text-uppercase">{PAGE_TITLE}</h2>

      <p className="mb-8">Sorry, the page you were looking for could not be found.</p>

      <Button
        linkButton={{ id: 'return', url: isAuthenticated ? '/dashboard' : '/' }}
        text={`Return ${isAuthenticated ? 'to dashboard' : 'to homepage'}`}
      />

    </SingleFormPage>
  );
};

// eslint-disable-next-line import/no-default-export
export default Error404Page;
